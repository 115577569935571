import queryString from 'query-string'
import { isArray, isString, isEmpty, toNumber } from 'lodash'
import pluralize from 'pluralize'

import { formatCallDuration, showFirstResult, showNestedResult } from '@/utils/helpers'

export const parseParams = (searchStr) => {
  const parsed = queryString.parse(searchStr)

  // Ignore everything else for call_id search, but don't append call_ids to call explorer page
  if (parsed.call_ids) {
    const callIdsQueried =
      typeof parsed.call_ids === 'string'
        ? [Number(parsed.call_ids)]
        : parsed.call_ids.map((callId) => Number(callId))

    return { call_ids: callIdsQueried, organizationId: Number(parsed.organizationId) }
  }

  const {
    playbooks,
    events,
    startDate,
    endDate,
    callDuration,
    maxCallDuration,
    includeCallsWithoutAudio,
    isWin,
    agents,
    tags,
    organizationId,
    keywords,
    keywordSearchOperator,
    keywordSideFilter,
    scorecardConfigScids,
    scorecardType,
    threshold,
    scorecards,
    scoredStatus,
    scorecardThreshold,
  } = parsed

  const formattedOrgId = Number(organizationId)
  const formattedStart = new Date(startDate)
  const formattedEnd = new Date(endDate)
  const initialFilters = {
    callDuration: formatCallDuration(callDuration),
    startDate: formattedStart,
    endDate: formattedEnd,
    organizationId: formattedOrgId,
    includeCallsWithoutAudio: includeCallsWithoutAudio === 'true',
    checklist: {
      selected: [],
      includes: true,
    },
    deck: {
      selected: [],
      includes: true,
    },
    postcall: {
      selected: [],
      includes: true,
    },
    notifications: {
      selected: [],
      includes: true,
    },
    openCategories: [],
    openSubcategories: [],
    scorecardConfigScids: [],
  }

  if (!isEmpty(events)) {
    const parseEvent = (event) => {
      const [, section] = event.split(' - ')
      initialFilters[section].selected.push(event)
      initialFilters[section].includes = parsed[section] === 'true'
    }
    if (typeof events === 'string') {
      parseEvent(events)
    } else {
      events.forEach(parseEvent)
    }
  }

  if (typeof maxCallDuration !== 'undefined') {
    initialFilters.maxCallDuration = maxCallDuration
  }

  if (isWin) {
    initialFilters.isWin = isWin
  }

  if (agents) {
    initialFilters.agents =
      typeof agents === 'string' ? [Number(agents)] : agents.map((agent) => Number(agent))
  }

  if (playbooks) {
    initialFilters.playbooks = typeof playbooks === 'string' ? [playbooks] : playbooks
  }

  if (keywords) {
    initialFilters.keywords =
      typeof keywords === 'string'
        ? [{ value: keywords }]
        : keywords.map((keyword) => ({ value: keyword }))
  }

  if (keywordSearchOperator) {
    initialFilters.keywordOptions = {}
    initialFilters.keywordOptions.logic = keywordSearchOperator
  }

  if (keywordSideFilter) {
    initialFilters.keywordOptions.side = keywordSideFilter
  }

  if (tags) {
    initialFilters.tags = typeof tags === 'string' ? [Number(tags)] : tags.map((tag) => Number(tag))
  }

  if (scorecardConfigScids) {
    initialFilters.scorecardConfigScids =
      typeof scorecardConfigScids === 'string' ? [scorecardConfigScids] : scorecardConfigScids
  }

  if (scorecardType) {
    initialFilters.scorecardType = scorecardType
  }

  if (threshold) {
    initialFilters.threshold = threshold
  }

  if (scorecards) {
    initialFilters.scorecards = typeof scorecards === 'string' ? [scorecards] : scorecards
    if (scoredStatus) {
      initialFilters.scoredStatus = scoredStatus
    }
    if (scorecardThreshold) {
      initialFilters.scorecardThreshold = scorecardThreshold
    }
  }
  return initialFilters
}

// this assumed you've loaded all your data into redux already
export const mapParamIds = (params) => (dispatch, getState) => {
  const { agents: agentIds, tags: tagIds, playbooks: playbookCids } = params
  const updatedParams = {
    ...params,
  }
  const { data } = getState().callSearch

  const { agents, tags, playbooks, scorecardConfigs } = data

  if (!isEmpty(agentIds)) {
    const updatedAgents = agentIds.map((id) => {
      const { label } = agents.find((agent) => agent.value === id)
      return {
        value: id,
        label,
      }
    })
    updatedParams.agents = updatedAgents
  }

  if (!isEmpty(playbookCids)) {
    const updatedPlaybooks = playbookCids
      .filter((cid) => playbooks.find((playbook) => playbook.value === cid))
      .map((cid) => {
        const { label } = playbooks.find((playbook) => playbook.value === cid)
        return {
          value: cid,
          label,
        }
      })
    updatedParams.playbooks = updatedPlaybooks
  }

  if (!isEmpty(tagIds)) {
    const updatedTags = tagIds.map((id) => {
      const { label, tag_cat } = tags
        .flatMap((tagCat) => tagCat.options)
        .find((tag) => tag.value === id)
      return {
        value: id,
        label,
        tag_cat,
      }
    })
    updatedParams.tags = updatedTags
  }

  if (!isEmpty(params.keywords)) {
    const updatedKeywords = params.keywords.map(({ value }) => {
      return {
        label: value,
        value,
      }
    })
    updatedParams.keywords = updatedKeywords
  }
  if (!isEmpty(params.scorecards)) {
    const updatedScorecards = params.scorecards
      .filter((sid) => scorecardConfigs.find((scorecard) => scorecard.sid === sid))
      .map((sid) => {
        const label = scorecardConfigs.find((scorecard) => {
          return scorecard.sid === sid
        })
        return {
          id: label.id,
          value: label.id,
          label: label.name,
          sid: label.sid,
        }
      })
    updatedParams.scorecards = updatedScorecards
  }
  return updatedParams
}

const formatDropdownOptions = (options) => {
  if (Array.isArray(options)) {
    return options?.map((option) => option.value)
  }
  return options.value
}

export const formatCallExplorerQueryString = (filters) => {
  // Ignore everything else for call_id search
  if (filters.call_ids) {
    const body = { call_ids: filters.call_ids, organizationId: filters.organizationId }

    return queryString.stringify(body)
  }

  const body = {
    organizationId: filters.organizationId,
    startDate: filters.startDate,
    endDate: filters.endDate,
    agents: filters.agents?.map(formatDropdownOptions),
    tags: filters.tags?.map(formatDropdownOptions),
    playbooks: filters.playbooks?.map(formatDropdownOptions),
    includeCallsWithoutAudio: filters.includeCallsWithoutAudio,
    callDuration: formatCallDuration(filters.callDuration),
    keywords: filters.keywords?.map(formatDropdownOptions),
    keywordSearchOperator: filters.keywordOptions?.logic,
    keywordSideFilter: filters.keywordOptions?.side,
    dispositions: filters.dispositions?.map(formatDropdownOptions),
  }

  if (filters.maxCallDuration) {
    body.maxCallDuration = filters.maxCallDuration
  }

  if (filters.isWin !== '') {
    body.isWin = filters.isWin
  }

  if (filters.scorecards) {
    body.scorecard_config_scids = filters.scorecards.map(formatDropdownOptions)
  }

  if (!isEmpty(filters.threshold)) {
    body.threshold = filters.threshold
  }

  if (!isEmpty(filters.scorecardType)) {
    body.scorecartType = filters.scorecardType
  }

  if (!isEmpty(filters.scorecardConfigScids)) {
    body.scorecardConfigScids = filters.scorecardConfigScids
  }

  if (!isEmpty(filters.scorecardType)) {
    body.scorecardType = filters.scorecardType
  }
  if (!isEmpty(filters.scorecards)) {
    body.scorecards = filters.scorecards.map((scorecard) => scorecard.sid)
    if (!isEmpty(filters.scoredStatus)) body.scoredStatus = filters.scoredStatus
    if (!isEmpty(filters.scorecardThreshold)) body.scorecardThreshold = filters.scorecardThreshold
  }
  // flatten the selected events to a single arr
  const { checklist, deck, notifications, postcall } = filters
  const allSections = {
    checklist,
    deck,
    notifications,
    postcall,
  }

  const events = []
  const sectionIncludes = {}

  Object.entries(allSections).forEach(([section, values]) => {
    if (isEmpty(values)) {
      return
    }

    const { selected, includes } = values

    if (isEmpty(selected)) {
      return
    }

    events.push(...selected)
    sectionIncludes[section] = includes
  })

  const currentBody = {
    ...body,
  }

  if (!isEmpty(events)) {
    currentBody.events = events
  }
  if (!isEmpty(sectionIncludes)) {
    Object.entries(sectionIncludes).forEach(([key, value]) => {
      currentBody[key] = value
    })
  }

  return queryString.stringify(currentBody)
}

export const getNestedPlaceholderText = (disabled, controlsVisible, showPill) => {
  const menuIsOpen = controlsVisible

  if (showPill) {
    return ''
  }

  if (disabled) {
    return 'None available'
  }

  if (menuIsOpen) {
    return 'Search...'
  }

  return 'Select item(s)'
}

export const getCallExplorerFilterDisplayValues = (filters) => ({
  callDuration: {
    label: 'Min. Call Duration',
    format: (accessor) => `${pluralize('minute', toNumber(filters[accessor]), true)}`,
  },
  maxCallDuration: {
    label: 'Max. Call Duration',
    format: (accessor) => `${pluralize('minute', toNumber(filters[accessor]), true)}`,
  },
  agents: {
    label: 'Agents',
    format: (accessor) => showFirstResult(filters[accessor]),
  },
  tags: {
    label: 'Tags',
    format: (accessor) => showFirstResult(filters[accessor]),
  },
  playbooks: {
    label: 'Playbooks',
    format: (accessor) => showFirstResult(filters[accessor]),
  },
  isWin: {
    label: 'Win',
    format: (accessor) => (filters[accessor] === 'true' ? 'Only Wins' : 'Only Non-wins'),
  },
  includeCallsWithoutAudio: {
    label: 'Calls Without Audio',
    format: (accessor) => (filters[accessor] ? 'Include' : 'Exclude'),
  },
  keywords: {
    label: 'Keywords',
    format: (accessor) => showFirstResult(filters[accessor]),
  },
  dispositions: {
    label: 'Dispositions',
    format: (accessor) => showFirstResult(filters[accessor]),
  },
  checklist: {
    label: 'Checklist',
    format: (accessor) => showNestedResult(filters[accessor]?.selected),
  },
  deck: {
    label: 'Dynamic Prompt',
    format: (accessor) => showNestedResult(filters[accessor]?.selected),
  },
  postcall: {
    label: 'Post Call',
    format: (accessor) => showNestedResult(filters[accessor]?.selected),
  },
  notifications: {
    label: 'Notifications',
    format: (accessor) => showNestedResult(filters[accessor]?.selected),
  },
  scorecards: {
    label: 'Scorecards',
    format: (accessor) => showFirstResult(filters[accessor]),
  },
  scoredStatus: {
    label: 'Scored Status',
    format: (accessor) => {
      if (filters[accessor] === 'scored') return 'Scored'
      if (filters[accessor] === 'unscored') return 'Not Scored'
      if (filters[accessor] === 'partially_scored') return 'Partially Scored'
      return 'Any'
    },
  },
  scorecardThreshold: {
    label: 'Scorecard Threshold',
    format: (accessor) => {
      if (filters[accessor] === 'meets_threshold') return 'Meets Expectations'
      if (filters[accessor] === 'exceeds_threshold') return 'Exceeds Expectations'
      return 'Needs Improvement'
    },
  },
})

export const callExplorerValueHasChanged = (initialState, filters, accessor) => {
  const initialValue = initialState.filters[accessor]
  const currentValue = filters[accessor]

  // isWin is an array with 3 states, and callDuration has a non-empty default value
  if (accessor === 'isWin' || accessor === 'callDuration') {
    return currentValue !== initialValue
  }

  if (isString(initialValue) || isArray(initialValue)) {
    return !isEmpty(filters[accessor])
  }

  // Nested filters have are in the `selected` property
  if (['checklist', 'deck', 'postcall', 'notifications'].includes(accessor)) {
    return !isEmpty(filters[accessor]?.selected)
  }

  return currentValue !== initialValue
}
