import moment from 'moment'

export const SET_LOADING = 'scorecards/setLoading'
export const SET_DATA = 'scorecards/setData'
export const SET_TABLE_HEADERS = 'scorecards/setTableHeaders'
export const SET_TABLE_ROWS = 'scorecards/setTableRows'
export const SET_FILTER = 'scorecards/setFilter'
export const SET_DATE_RANGE_FILTER = 'scorecards/setDateRangeFilter'
export const CLEAR_FILTERS = 'scorecards/clearFilters'

// Action Creators
export const setLoading = (section, payload) => ({ type: SET_LOADING, section, payload })
export const setData = (section, payload) => ({ type: SET_DATA, section, payload })
export const setTableHeaders = (section, payload) => ({ type: SET_TABLE_HEADERS, section, payload })
export const setTableRows = (section, payload) => ({ type: SET_TABLE_ROWS, section, payload })
export const setFilter = (section, payload) => ({ type: SET_FILTER, section, payload })
export const setDateRangeFilter = (payload) => ({ type: SET_DATE_RANGE_FILTER, payload })
export const clearFilters = () => ({ type: CLEAR_FILTERS })

// State
export const initialState = {
  data: {
    scorecards: [],
    agents: [],
    tags: [],
    playbooks: [],
    dispositions: [],
    targetPlaybook: {},
    aggregateScores: {},
    tableHeaders: {
      advancedExports: [],
      scorecardDashboard: [],
    },
    tableRows: {
      advancedExports: [],
      scorecardDashboard: [],
    },
    criteriaAuditEvents: [],
    criteriaScoresByMeasure: {},
    aggregateScoresByUser: [],
    aggregateFilters: {},
    currentOrganizationId: '',
    scores: [],
    allScores: [],
    displayNotableCalls: false,
    currentScore: {},
    scorecardOptions: [],
    scorecardConfigs: [],
    scorecardAssociations: [],
    aboveThreshold: [],
    belowThreshold: [],
    targetScorecardMissingCriteria: [],
    targetScorecardConfig: {},
    targetScorecardSection: {},
    callsCount: 0,
    selectedScorecards: [],
    reporting: {},
    reportingByDay: {},
    reportingComparison: {},
    manualScores: [],
    qaAuditEvents: [],
    disputes: [],
    filteredScores: [],
  },
  loading: {
    all: false,
    scorecards: false,
    agents: false,
    tags: false,
    playbooks: false,
    dispositions: false,
    csv: false,
    advancedExportsStreamedCsv: false,
    targetPlaybook: false,
    criteriaAuditEvents: false,
    aggregateScores: false,
    aggregateScoresByUser: false,
    aggregateFilters: false,
    tableHeaders: {},
    tableRows: {},
    notableCalls: false,
    missingCriteria: false,
    scores: false,
    scoresTable: false,
    currentScore: false,
    scorecardOptions: false,
    scorecardConfigs: false,
    targetScorecardConfig: false,
    targetScorecardSection: false,
    callsCount: false,
    reporting: false,
    reportingComparison: false,
    qaAuditEvents: false,
    disputes: false,
  },
  filters: {
    organization: null,
    scorecards: [],
    agents: [],
    tags: [],
    playbooks: [],
    dispositions: [],
    groupBy: [],
    metrics: [],
    scoredStatus: '',
    isWin: null,
    scorecard: null,
    includeManagers: false,
    callDuration: '',
    startDate: moment(Date.now()).startOf('day'),
    endDate: moment(Date.now()).endOf('day'),
    scorecard_type: 'automated',
  },
}

// Reducer
export default function scorecardsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, data: { ...state.data, [action.section]: action.payload } }
    case SET_TABLE_HEADERS:
      return {
        ...state,
        data: {
          ...state.data,
          tableHeaders: { ...state.data.tableHeaders, [action.section]: action.payload },
        },
      }
    case SET_TABLE_ROWS:
      return {
        ...state,
        data: {
          ...state.data,
          tableRows: { ...state.data.tableRows, [action.section]: action.payload },
        },
      }
    case SET_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [action.section]: action.payload },
      }
    case SET_DATE_RANGE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      }
    case CLEAR_FILTERS:
      return { ...state, filters: { ...initialState.filters } }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, [action.section]: action.payload } }
    default:
      return state
  }
}
