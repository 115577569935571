import queryString from 'query-string'
import { cloneDeep, defaultsDeep, isEmpty, isNil, toUpper } from 'lodash'
import { toast } from 'react-toastify'
import {
  formatCallDuration,
  getDateRangeFromSavedFilter,
  getTagsWithCategories,
} from '@/utils/helpers'

import { apiService, fetchingAPI } from '@/api'
import { history } from '@/entry'
import { initialPlaybookBody } from '@/views/Playbooks/playbook.helpers'
import { parseToCsv } from '@/components/helpers/parseToCsv'
import { exportCsv } from '@/components/helpers/exportToCsv'
import {
  clearFiltersAndSelectOrg,
  initialState,
  setCallExplorerCall,
  setCallExplorerData,
  setCallExplorerLoading,
  setCalls,
  setData,
  setError,
  setFilters,
  setLoading,
} from './callSearch.redux'
import { formatCallExplorerQueryString, mapParamIds, parseParams } from './helpers'

import { CALL_EXPLORER_FILTER_TYPE } from '../savedFilters/savedFilters.constants'
import { fetchSavedFilters } from '../savedFilters/savedFilters.actions'

export const formatSection = (section, filters) => {
  const { includes, selected } = filters

  const formattedEntries = selected.map((context) => {
    const formattedEntry = {
      category: '',
      entry: '',
      decklist_entry: '',
      cid: '',
    }

    const splitContext = context.split(' - ')
    const isNested = splitContext.length > 3
    if (isNested) {
      splitContext.forEach((contextSection, index) => {
        // index 0 is section which we already know
        if (index === 0) {
          formattedEntry.cid = contextSection
        } else if (index === 2) {
          formattedEntry.category = contextSection
        } else if (index === 3) {
          formattedEntry.entry = contextSection
        } else if (index === 4) {
          formattedEntry.decklist_entry =
            contextSection === 'No response used' ? '' : contextSection
        }
      })
    } else {
      /* eslint-disable-next-line prefer-destructuring */
      formattedEntry.entry = splitContext[2]
      /* eslint-disable-next-line prefer-destructuring */
      formattedEntry.cid = splitContext[0]
    }

    return formattedEntry
  })

  return {
    section,
    includes,
    filters: formattedEntries,
  }
}

const findNoResUsed = (contextStr) => contextStr.toLowerCase().includes('no response used')
export const formatNoResponseUsed = (deckFilters, playbookData) => {
  const formattedDecklists = []

  playbookData.forEach((playbook) => {
    const { deck, cid } = playbook

    // Find context strings that match the current playbook's CID
    const matchingContexts = deckFilters.selected.filter((contextStr) => {
      const [contextCid] = contextStr.split(' - ')
      return contextCid === cid
    })

    if (isEmpty(matchingContexts)) {
      return // No matching context for this playbook, move to next
    }

    matchingContexts.forEach((contextStr) => {
      const [cid, , category, entry] = contextStr.split(' - ')
      const selectedCategory = deck.find((catObj) => catObj.category === category)
      if (!selectedCategory) return

      const selectedEntry = selectedCategory.items.find((entryObj) => entryObj.item === entry)
      if (!selectedEntry) return

      selectedEntry.items
        .filter((decklistEntry) => !decklistEntry.toLowerCase().includes('no response used'))
        .forEach((decklistEntry) => {
          formattedDecklists.push({
            category,
            entry,
            decklist_entry: decklistEntry,
            cid,
          })
        })
    })
  })

  return {
    section: 'deck',
    includes: !deckFilters.includes,
    is_no_response_used: true,
    filters: formattedDecklists,
  }
}

export const formatEvents = (filters, playbookData) => {
  const { checklist, deck, notifications, postcall } = filters
  const formattedSections = []

  if (!isEmpty(checklist.selected)) {
    formattedSections.push(formatSection('checklist', checklist))
  }
  if (!isEmpty(deck.selected)) {
    const requiredParents = new Set()
    // this could be optimized by putting the filters in this for each too
    // but that's too much work for such a small array of stuff
    // if you have any decklist items selected regardless if you select "occurs"
    // we need to only pull calls where the parent events happens
    deck.selected.forEach((contextStr) => {
      const splitContext = contextStr.split(' - ')
      // if context is less than 4 then it's not a decklist item
      // e.g. deck - Objections - Budget - "Have you tried making more monies?"
      if (splitContext.length < 5) {
        return
      }
      const splitParent = splitContext.slice(0, 4)
      const parentContext = splitParent.join(' - ')
      requiredParents.add(parentContext)
    })
    const noResUsedSelected = deck.selected.filter(findNoResUsed)
    const selectedDecklists = deck.selected.filter((contextStr) => {
      const isNoResUsed = contextStr.toLowerCase().includes('no response used')
      const isRequiredParent = requiredParents.has(contextStr)
      return !(isRequiredParent || isNoResUsed)
    })

    if (!isEmpty(selectedDecklists)) {
      formattedSections.push(formatSection('deck', { ...deck, selected: selectedDecklists }))
    }
    if (!isEmpty(requiredParents)) {
      formattedSections.push(
        formatSection('deck', { ...deck, includes: true, selected: Array.from(requiredParents) })
      )
    }
    if (!isEmpty(noResUsedSelected)) {
      formattedSections.push(
        formatNoResponseUsed({ ...deck, selected: noResUsedSelected }, playbookData)
      )
    }
  }
  if (!isEmpty(notifications.selected)) {
    formattedSections.push(formatSection('notifications', notifications))
  }
  if (!isEmpty(postcall.selected)) {
    formattedSections.push(formatSection('classified_postcall', postcall))
  }
  return formattedSections
}

export const formatCallFilters = (filters, playbookData) => {
  // Ignore everything else for call_id search
  if (filters.call_ids) {
    const body = { call_ids: filters.call_ids, organization_id: filters.organizationId }

    return body
  }

  const body = {
    organization_id: filters.organizationId,
    start_date: filters.startDate,
    end_date: filters.endDate,
    agent_ids: filters.agents?.map((x) => x.value),
    tags: filters.tags?.map((tagObj) => ({
      category_id: tagObj.tag_cat,
      tag_id: tagObj.value,
    })),
    playbook_cids: filters.playbooks?.map((x) => x.value),
    call_duration: formatCallDuration(filters.callDuration),
    max_call_duration: isEmpty(filters.maxCallDuration) ? 0 : filters.maxCallDuration,
    audio_required: !filters.includeCallsWithoutAudio,
    keywords: isEmpty(filters.keywords) ? [] : filters.keywords.map((keyword) => keyword.value),
    keyword_search_operator: toUpper(filters.keywordOptions?.logic),
    keyword_side_filter:
      filters.keywordOptions?.side === 'both' ? null : toUpper(filters.keywordOptions?.side),
    dispositions: isEmpty(filters.dispositions)
      ? []
      : filters.dispositions.map((dispo) => dispo.value),
  }

  if (filters.isWin !== '') {
    body.is_win = filters.isWin
  }

  const events = formatEvents(filters, playbookData)

  if (!isEmpty(events)) {
    body.events = events
  }

  if (filters.threshold) {
    body.threshold = filters.threshold
  }

  if (filters.scorecardConfigScids) {
    body.scorecard_config_scids = filters.scorecardConfigScids
  }

  if (filters.scorecardType) {
    body.scorecard_type = filters.scorecardType
  }
  if (filters.scorecards) {
    body.scorecards = filters.scorecards.map((scorecard) => scorecard.sid)
  }

  if (filters.scoredStatus && filters.scoredStatus !== 'any') {
    body.scored_status = filters.scoredStatus
  }
  if (filters.scorecardThreshold) body.scorecard_threshold = filters.scorecardThreshold
  return body
}

export const fetchCalls = ({ filters, playbookData }) => {
  return async (dispatch) => {
    dispatch(setLoading({ calls: true }))

    if (!filters.call_ids) {
      const currentFiltersQueryStr = formatCallExplorerQueryString(filters)
      history.push(`/call-explorer?${currentFiltersQueryStr}`)
    }
    const body = formatCallFilters(filters, playbookData)

    try {
      const { calls } = await fetchingAPI(
        `${apiService.reporting}/api/calls`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )

      dispatch(setCalls(calls))
    } catch (err) {
      dispatch(setCalls([]))
      toast.error('Failed to fetch calls')
    } finally {
      dispatch(setLoading({ calls: false }))
    }
  }
}

// Call Search Helpers
export const fetchCallsFromLatestFilters = () => async (dispatch, getState) => {
  const { filters, data } = getState().callSearch

  if (filters.organizationId) {
    dispatch(fetchCalls({ filters, playbookData: data.playbookData }))
  }
}
export const fetchScorecardsByOrg =
  (organizationId, scorecardType = null) =>
  async (dispatch) => {
    dispatch(setLoading({ scorecards: true }))
    let url = `${apiService.scorecard}/scoring/scorecards/current?requested_organization_id=${organizationId}`
    if (scorecardType) {
      url += `&scorecard_type=${scorecardType}`
    }
    try {
      const apiScorecardConfigs = await fetchingAPI(url, 'GET', dispatch)
      const sortedScorecardConfigs = apiScorecardConfigs.sort((a, b) =>
        a.name?.localeCompare(b.name)
      )

      dispatch(setData({ scorecardConfigs: sortedScorecardConfigs }))
    } catch (err) {
      toast.error(`Failed to fetch scorecards for organization: ${organizationId}`)
    } finally {
      dispatch(setLoading({ scorecards: false }))
    }
  }

export const applyFilterAndFetchCalls = (accessor, value) => async (dispatch) => {
  dispatch(setFilters({ [accessor]: value }))

  await dispatch(fetchCallsFromLatestFilters())
}

export const removeFilters = (accessor) => async (dispatch) => {
  dispatch(setFilters({ [accessor]: initialState.filters[accessor] }))
}

export const removeFilterAndFetchCalls = (accessor) => async (dispatch) => {
  dispatch(removeFilters(accessor))

  await dispatch(fetchCallsFromLatestFilters())
}

// Clears all filters except the organization selected
export const clearAllFilters = (selectedOrganizationId) => async (dispatch, getState) => {
  const { organizationid: defaultOrganizationId } = getState().currentUser
  const organizationId = selectedOrganizationId || defaultOrganizationId

  // Clear saved filter
  dispatch(setData({ selectedSavedFilterId: null }))

  // If no org is passed, use the user's default org.
  dispatch(clearFiltersAndSelectOrg(organizationId))
}

export const clearAllFiltersAndFetchCalls = (selectedOrganizationId) => async (dispatch) => {
  dispatch(clearAllFilters(selectedOrganizationId))

  await dispatch(fetchCallsFromLatestFilters())
}

export const clearSavedFilter = (shouldLoadCalls) => (dispatch) => {
  if (shouldLoadCalls) {
    dispatch(clearAllFiltersAndFetchCalls())
  } else {
    dispatch(clearAllFilters())
  }
}

export const setSavedFilterById =
  (filterId, shouldLoadCalls = true) =>
  async (dispatch, getState) => {
    const { savedFilterList } = getState().savedFilters
    const savedCallExplorerFilters = savedFilterList[CALL_EXPLORER_FILTER_TYPE]

    const filterToApply = savedCallExplorerFilters?.find((filter) => filter.uuid === filterId)

    if (filterToApply?.filters) {
      dispatch(setData({ selectedSavedFilterId: filterId }))

      // Set actual start/end times based on static range
      const { startDate, endDate } = getDateRangeFromSavedFilter(filterToApply.filters.dateRange)

      const updatedFilters = {
        ...filterToApply.filters,
        organizationId: filterToApply.filters.organization,
        scorecards: filterToApply.filters.scorecards || [],
        startDate,
        endDate,
      }

      dispatch(setFilters(updatedFilters))

      if (shouldLoadCalls) {
        const updatedMergedFilters = getState().callSearch.filters
        // TODO: These filters are not sanitized from the BE, and the BE is just a JSONB string.
        // We should sanitize these filters before sending them to the API
        await dispatch(fetchCalls({ filters: updatedMergedFilters }))
      }
    }
  }

export const fetchAudioUrl = (id) => async (dispatch) => {
  try {
    const {
      audio_address: url,
      expiration_seconds: lifetime,
      audio_file_status: audioStatus,
    } = await fetchingAPI(`${apiService.reporting}/api/calls/${id}/audio`, 'GET', dispatch)

    dispatch(
      setCallExplorerData({
        audioUrl: url,
        audioUrlExpiration: lifetime,
        audioStatus,
      })
    )
  } catch {
    console.error('Api error encountered while fetching audio URL')
    dispatch(
      setCallExplorerData({
        audioError: true,
      })
    )
  }
}

export const fetchScreenCaptureUrls = (id) => async (dispatch) => {
  try {
    const { video_addresses: urls, video_file_status: status } = await fetchingAPI(
      `${apiService.reporting}/api/calls/${id}/screen_capture`,
      'GET',
      dispatch
    )

    dispatch(
      setCallExplorerData({
        screenCaptureUrls: urls,
        screenCaptureStatus: status,
        screenCaptureError: false,
      })
    )
  } catch {
    console.error('Api error encountered while fetching the screen capture URL')
    dispatch(
      setCallExplorerData({
        screenCaptureError: true,
      })
    )
  }
}

export const fetchSummariesForCallIds = (call_ids) => async (dispatch, getState) => {
  const { calls } = getState().callSearch
  const callsCopy = cloneDeep(calls).map((call) => {
    if (call_ids.includes(call.call_id)) {
      return {
        ...call,
        // this empty object is tracking which call_ids we make requests for.
        // If call_summary is empty object we requested and got nothing back so
        // no summary otherwise request if this call is rendered and call_summary is undefined
        call_summary: {},
      }
    }
    return call
  })
  try {
    const summaries = await fetchingAPI(
      `${apiService.summary}/summaries/multiple`,
      'POST',
      dispatch,
      JSON.stringify({ call_ids })
    )
    callsCopy.forEach((call) => {
      if (call.call_summary) {
        const summaryForCall = summaries.find((summary) => summary.call_id === call.call_id)
        if (summaryForCall?.parsed_summary) {
          Object.assign(call, { call_summary: summaryForCall.parsed_summary })
          // ensure we don't overwrite any reserved keys on the call object
          Object.assign(call, { ...summaryForCall.parsed_summary, ...call })
        }
      }
    })
    dispatch(setCalls(callsCopy))
  } catch (error) {
    console.error('Unable to fetch summaries')
    toast.error('Failed to fetch summaries')
  }
}

export const fetchVoipCustomerCalls = (call) => async (dispatch) => {
  const {
    voip_customer_id: voipCustomerId,
    organization_id: organizationId,
    call_id: callId,
  } = call.metadata

  try {
    const voipCustomerCallRes = await fetchingAPI(
      `${apiService.reporting}/api/calls/customer/${voipCustomerId}?organization_id=${organizationId}`
    )
    // we dont wanna show the call the user is looking at in the count
    const filteredCalls = voipCustomerCallRes
      .filter(({ call_id }) => call_id !== callId)
      .map(({ call_start_time, ...rest }) => {
        // +Z sets it to UTC
        const totalMs = new Date(`${call_start_time}Z`).getTime()
        return {
          call_start_time: totalMs,
          ...rest,
        }
      })
    dispatch(setData({ voipCustomerCalls: filteredCalls }))
  } catch (err) {
    if (err.message === 'No previous calls found with this customer') {
      return
    }
    toast.error('Failed to fetch calls with matching Voip Customer ID')
  }
}

export const getCoachingComments = (callId) => async (dispatch, getState) => {
  const { organizationid: orgId } = getState().currentUser
  const coachingComments = await fetchingAPI(
    `${apiService.reporting}/api/coaching_comments?call_ids=${callId}&organization_id=${orgId}`,
    'GET',
    dispatch
  )
  const callComments = coachingComments
    .filter((comment) => !comment.deleted)
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at)
    })
  return callComments
}

export const fetchCoachingCommentsByCall = (callId) => async (dispatch) => {
  try {
    const callComments = await dispatch(getCoachingComments(callId))

    dispatch(setCallExplorerData({ callComments }))
  } catch (err) {
    toast.error('Failed to fetch comments')
  }
}

export const fetchCall = (id, queryStr) => async (dispatch) => {
  let url = `${apiService.reporting}/api/calls/${id}`

  if (queryStr) {
    url += `?${queryStr}`
  }

  const call = await fetchingAPI(url, 'GET', dispatch)
  if (call.postcall_transcript.length) {
    call.copilotAnchors = call.postcall_transcript
      .flatMap((transcript) => transcript.data)
      .filter(
        (
          { type, criteria_name, scorecard_name } = {
            type: null,
            criteria_name: '',
            scorecard_name: '',
          }
        ) => type === 'anchor' && criteria_name && scorecard_name
      )
      .map((anchor, index) => {
        return {
          ...anchor,
          id: `anchor-${index}`,
        }
      })
  }

  // Set unique ids on events, since timestamps can be the same
  call.analysis = call.analysis.map((event, index) => {
    const { category, item, timestamp, section, sub_events: subEvents, question, answer } = event
    const eventId = `${index}`

    // set unique ids on subevents as well
    // since subevent timestamps can be the same
    let subEventsFormatted = subEvents
    if (subEvents) {
      // No Response Used for deck
      if (subEvents.length === 0) {
        subEventsFormatted = [
          {
            id: `${eventId}-0`,
            item: 'No Response Used',
            isNoResponse: true,
            timestamp,
            section,
          },
        ]
      } else {
        // Regular deck list events
        subEventsFormatted = subEvents.map((subevent, index) => {
          return { ...subevent, id: `${eventId}-${index}`, section }
        })
      }
    }

    return {
      id: eventId,
      category,
      item,
      timestamp,
      section,
      question,
      answer,
      ...(subEventsFormatted && { subEvents: subEventsFormatted }),
    }
  })

  // Flatten all events so deck list events appear in event timelines
  call.analysisFlattened = call.analysis.reduce((prev, currEvent) => {
    const { subEvents, ...eventProperties } = currEvent

    prev.push({
      ...eventProperties,
    })

    if (subEvents) {
      prev.push(...subEvents)
    }

    return prev
  }, [])

  return call
}

export const fetchCallExplorer =
  ({ id, keywords }) =>
  // eslint-disable-next-line consistent-return
  async (dispatch) => {
    dispatch(setCallExplorerLoading(true))
    try {
      const querystring = new URLSearchParams()
      keywords?.map((keyword) => querystring.append('keywords', keyword.value))
      const queryStr = querystring.toString()

      const call = await dispatch(fetchCall(id, queryStr))
      dispatch(setCallExplorerCall({ ...call, callId: id }))
      await dispatch(fetchAudioUrl(id))

      if (call.metadata.screen_capture_available) {
        await dispatch(fetchScreenCaptureUrls(id))
      } else {
        dispatch(
          setCallExplorerData({
            screenCaptureUrls: [],
            screenCaptureStatus: 'not_available',
            screenCaptureError: false,
          })
        )
      }

      await dispatch(fetchCoachingCommentsByCall(id))
      // if (call.metadata.voip_customer_id) {
      //   await dispatch(fetchVoipCustomerCalls(call))
      // }
    } catch (err) {
      if (err?.message === `Call hasn't ended yet, can't report`) {
        return dispatch(setError({ callExplorer: true }))
      }

      return toast.error('Failed to fetch call')
    } finally {
      dispatch(setCallExplorerLoading(false))
    }
  }

export const fetchAgentsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ agents: true }))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )

    users.sort((a, b) => a.last_name.localeCompare(b.last_name))
    const agentOptions = users.map((agent) => ({
      value: agent.id,
      label: `${agent.first_name} ${agent.last_name}`,
    }))

    dispatch(setData({ agents: agentOptions }))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(setLoading({ agents: false }))
  }
}

export const fetchTagsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagsOptionsWithCategories = getTagsWithCategories(tags, tagCategories)

    dispatch(setData({ tags: tagsOptionsWithCategories }))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(setLoading({ tags: false }))
  }
}

export const fetchPlaybooksByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ playbooks: true }))

  try {
    const configProperties = queryString.stringify({ requested_properties: 'id,name,cid' })
    const playbooks = await fetchingAPI(
      `${apiService.web}/api/${organizationId}/configs?${configProperties}`,
      'GET',
      dispatch
    )
    const playbookOptions = playbooks
      .map((playbook) => ({ value: playbook.cid, label: playbook.name }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData({ playbooks: playbookOptions }))
  } catch (err) {
    toast.error('Failed to fetch playbooks')
  } finally {
    dispatch(setLoading({ playbooks: false }))
  }
}

export const fetchDispositionsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ dispositions: true }))

  try {
    const dispositions = await fetchingAPI(
      `${apiService.reporting}/api/dispositions?organization_id=${organizationId}`,
      'GET',
      dispatch
    )
    const dispoOptions = dispositions
      .map(({ name }) => ({ value: name, label: name }))
      .sort((a, b) => a.label.localeCompare(b.label))
    dispatch(setData({ dispositions: dispoOptions }))
  } catch (err) {
    toast.error('Failed to fetch dispositions')
  } finally {
    dispatch(setLoading({ dispositions: false }))
  }
}

export const setHardSelectedEvent = (newEvent) => (dispatch, getState) => {
  const {
    callSearch: { callExplorer },
  } = getState()
  const { hardSelectedEvent: currentHardSelectedEvent } = callExplorer
  // If we are trying to select an already select event, clear it instead
  const timestampsEqual = currentHardSelectedEvent?.timestamp === newEvent.timestamp
  const idsEqual = currentHardSelectedEvent?.id === newEvent.id
  if (timestampsEqual && idsEqual) {
    dispatch(setCallExplorerData({ hardSelectedEvent: null }))
  } else {
    dispatch(setCallExplorerData({ hardSelectedEvent: newEvent }))
  }
}

export const fetchPlaybookData = (filters) => async (dispatch) => {
  dispatch(setLoading({ playbookData: true }))
  const { startDate: start_date, endDate: end_date, organizationId: organization_id } = filters

  try {
    const playbookRes = await Promise.all(
      filters.playbooks.map(async (playbook) => {
        try {
          const body = { start_date, end_date, organization_id, config_cid: playbook.value }
          return await fetchingAPI(
            `${apiService.reporting}/api/playbook_event_filters?config_cid=${playbook.value}`,
            'POST',
            dispatch,
            JSON.stringify(body)
          )
        } catch {
          return { config_cid: playbook.value, error: true }
        }
      })
    )
    const playbookResUpdated = playbookRes.filter((playbookRes) => {
      return isNil(playbookRes.error)
    })
    const updatedPlaybookData = []

    playbookResUpdated.forEach((playbook) => {
      playbook.deck = playbook?.deck?.map((category) => {
        const updatedSubcategories = category.items.map((item) => {
          if (!item.has_sub_items) {
            return item
          }
          const updatedItems = [...item.items, 'No Response Used']

          return { ...item, items: updatedItems }
        })

        return {
          ...category,
          items: updatedSubcategories,
          playbookName: playbook.playbook_name,
          cid: playbook.cid,
        }
      })
      updatedPlaybookData.push(playbook)
    })
    dispatch(
      setData({
        playbookData: updatedPlaybookData,
      })
    )
  } catch {
    // Do nothing!
  } finally {
    dispatch(setLoading({ playbookData: false }))
  }
}

export const loadInitialData = (searchParams) => async (dispatch, getState) => {
  dispatch(setCallExplorerLoading(true))

  const {
    organizationid: currentUserOrg,
    hierarchy_manager,
    edit_qa_copilot,
  } = getState().currentUser

  const isBaltoAdmin = currentUserOrg === 1
  const isHierarchyManagerWithCopilot = !isBaltoAdmin && hierarchy_manager && edit_qa_copilot

  try {
    const initialFilters = parseParams(searchParams)
    const { organizationId } = initialFilters
    const shouldLoadInitialData = !isEmpty(initialFilters)
    const shouldBlockCrossOrgRequestForFilterData =
      isHierarchyManagerWithCopilot && organizationId !== '' && organizationId !== currentUserOrg

    if (!shouldLoadInitialData) {
      return
    }

    // Ignore everything else for call_id search
    if (initialFilters.call_ids) {
      await dispatch(
        fetchCalls({
          filters: {
            call_ids: initialFilters.call_ids,
            organizationId: initialFilters.organizationId,
          },
        })
      )
    } else {
      if (!shouldBlockCrossOrgRequestForFilterData) {
        await Promise.all([
          dispatch(fetchAgentsByOrg(organizationId)),
          dispatch(fetchTagsByOrg(organizationId)),
          dispatch(fetchPlaybooksByOrg(organizationId)),
          dispatch(fetchScorecardsByOrg(organizationId)),
        ])
      }
      // after we get all the api responses map playbook/tags/agent ids
      // to their associated dropdown
      const formattedFilters = dispatch(mapParamIds(initialFilters))
      const { playbooks: formattedPlaybooks } = formattedFilters
      if (formattedPlaybooks?.length === 1) {
        await dispatch(fetchPlaybookData(formattedFilters))
      }

      dispatch(setFilters(formattedFilters))
      await dispatch(fetchCalls({ filters: formattedFilters }))
    }
  } catch (error) {
    toast.error('Failed to load initial query')
  } finally {
    dispatch(setCallExplorerLoading(false))
  }
}

export const loadDefaultCalls = () => async (dispatch, getState) => {
  dispatch(setCallExplorerLoading(true))

  const {
    organizationid: currentUserOrgId,
    own_organization_id: ownOrganizationId,
    hierarchy_manager: isHierarchyManager,
  } = getState().currentUser
  const { filters } = getState().callSearch
  const childOrgSelected = isHierarchyManager && currentUserOrgId !== ownOrganizationId

  try {
    // Check if a default filter exists, unless using hierarchy child org
    if (!childOrgSelected) {
      await dispatch(fetchSavedFilters(CALL_EXPLORER_FILTER_TYPE))
      const callExplorerSavedFilters =
        getState().savedFilters.savedFilterList[CALL_EXPLORER_FILTER_TYPE]

      // Find the default saved filter, if exists
      const defaultFilter = callExplorerSavedFilters.find((filter) => filter.is_default)

      if (defaultFilter && !isEmpty(defaultFilter.filters)) {
        dispatch(setSavedFilterById(defaultFilter.uuid))
      } else {
        // Fetch the default
        dispatch(fetchCalls({ filters: { ...filters, organizationId: currentUserOrgId } }))
      }
    } else {
      // Fetch the default
      dispatch(fetchCalls({ filters: { ...filters, organizationId: currentUserOrgId } }))
    }
  } catch (error) {
    toast.error('Failed to load initial query')
  } finally {
    dispatch(setCallExplorerLoading(false))
  }
}

export const fetchQAScoresByCallId = (callId, organizationId) => async (dispatch) => {
  dispatch(setLoading({ qaScores: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/by_call_id/${callId}?requested_organization_id=${organizationId}`,
      'GET',
      dispatch
    )

    const associatedCallIds = [
      ...new Set(response.flatMap((scorecard) => scorecard.associated_calls)),
    ]

    dispatch(setCallExplorerData({ qaScores: response, associatedCallIds }))
  } catch (err) {
    toast.error('Failed to fetch QA scores')
  } finally {
    dispatch(setLoading({ qaScores: false }))
  }
}

export const fetchCallSummaryByCallId = (callId) => async (dispatch) => {
  dispatch(setLoading({ callSummary: true }))

  try {
    const response = await fetchingAPI(`${apiService.summary}/summaries/${callId}`, 'GET', dispatch)

    dispatch(setCallExplorerData({ callSummary: response }))
  } catch {
  } finally {
    dispatch(setLoading({ callSummary: false }))
  }
}

export const updateScoreCriteria = (scorecardId, updatedCriteria) => async (dispatch, getState) => {
  dispatch(setLoading({ qaScores: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/${scorecardId}/update_criteria`,
      'POST',
      dispatch,
      JSON.stringify(updatedCriteria)
    )

    const { qaScores } = getState().callSearch.callExplorer
    const updatedScores = qaScores.map((score) => (score.id === scorecardId ? response : score))

    dispatch(setCallExplorerData({ qaScores: updatedScores }))
  } catch {
    toast.error('Failed to fetch playbook data')
  } finally {
    dispatch(setLoading({ qaScores: false }))
  }
}

export const updateScorecardNote = (scorecardId, noteText) => async (dispatch, getState) => {
  dispatch(setLoading({ qaScores: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/notes/${scorecardId}`,
      'POST',
      dispatch,
      JSON.stringify({ note_text: noteText })
    )

    const { qaScores } = getState().callSearch.callExplorer
    const updatedScores = qaScores.map((score) => (score.id === scorecardId ? response : score))
    dispatch(setCallExplorerData({ qaScores: updatedScores }))
  } catch {
    toast.error('Failed to save scorecard note')
  } finally {
    dispatch(setLoading({ qaScores: false }))
  }
}

export const deleteScorecardNote = (scorecardId) => async (dispatch, getState) => {
  dispatch(setLoading({ qaScores: true }))

  try {
    const response = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/notes/${scorecardId}`,
      'DELETE',
      dispatch
    )

    const { qaScores } = getState().callSearch.callExplorer
    const updatedScores = qaScores.map((score) => (score.id === scorecardId ? response : score))
    dispatch(setCallExplorerData({ qaScores: updatedScores }))
  } catch {
    toast.error('Failed to delete scorecard note')
  } finally {
    dispatch(setLoading({ qaScores: false }))
  }
}

// Shortcut handling
export const handleShortcutNext = (filteredCriteriaOrdered) => async (dispatch, getState) => {
  const { selectedCriteriaFocusIndex } = getState().callSearch.callExplorer

  const finalCriteriaIndex = filteredCriteriaOrdered.length - 1
  if (selectedCriteriaFocusIndex < finalCriteriaIndex) {
    const nextCriteriaIndex = selectedCriteriaFocusIndex + 1
    dispatch(setCallExplorerData({ selectedCriteriaFocusIndex: nextCriteriaIndex }))
    return filteredCriteriaOrdered[nextCriteriaIndex]
  }
  return null
}

export const handleShortcutPrevious = (filteredCriteriaOrdered) => async (dispatch, getState) => {
  const { selectedCriteriaFocusIndex } = getState().callSearch.callExplorer

  if (selectedCriteriaFocusIndex > 0) {
    const previousCriteriaIndex = selectedCriteriaFocusIndex - 1
    dispatch(setCallExplorerData({ selectedCriteriaFocusIndex: previousCriteriaIndex }))
    return filteredCriteriaOrdered[previousCriteriaIndex]
  }
  return null
}

export const handleShortcutChangeScore =
  (filter, handleChangeScore, filteredCriteriaOrdered) => async (dispatch, getState) => {
    const { selectedCriteriaFocusIndex } = getState().callSearch.callExplorer
    const selectedCriteria = filteredCriteriaOrdered[selectedCriteriaFocusIndex]
    const scoreMap = { pass: 1, fail: 0, na: -1 }

    handleChangeScore({ [selectedCriteria.uuid]: scoreMap[filter] })
  }

export const resolveDispute = (criteriaScore, userId) => async (dispatch) => {
  try {
    const { dispute } = criteriaScore
    const body = {
      dispute_id: dispute.id,
      scorecard_criteria_scores_id: dispute.scorecard_criteria_scores_id,
      manager_comment: dispute.manager_comment || '',
      manager_id: userId,
      status: dispute.status,
      score: dispute.score,
    }
    return await fetchingAPI(
      `${apiService.scorecard}/disputes/criteria`,
      'PUT',
      dispatch,
      JSON.stringify(body)
    )
  } catch (err) {
    toast.error('Failed to resolve the dispute')
    return false
  }
}

export const postCoachingComment = (commentText, callId) => async (dispatch, getState) => {
  const { user_id, organizationid: orgId } = getState().currentUser
  const body = { comment: commentText.trim(), call_id: callId, user_id }
  return fetchingAPI(
    `${apiService.reporting}/api/coaching_comments?organization_id=${orgId}`,
    'POST',
    dispatch,
    JSON.stringify(body)
  )
}

export const addCoachingComment = (commentText, callId) => async (dispatch) => {
  try {
    await dispatch(postCoachingComment(commentText, callId))
    await dispatch(fetchCoachingCommentsByCall(callId))
    toast.success('Comment added')
  } catch (err) {
    toast.error('Failed to add comment')
  }
}

export const patchCoachingComment = (updatedProperty, commentId) => async (dispatch, getState) => {
  const { organizationid: orgId, user_id } = getState().currentUser
  return fetchingAPI(
    `${apiService.reporting}/api/coaching_comments/${commentId}?organization_id=${orgId}`,
    'PATCH',
    dispatch,
    JSON.stringify({ ...updatedProperty, user_id })
  )
}

export const updateCoachingComment = (updatedProperty, commentId, callId) => async (dispatch) => {
  try {
    await dispatch(patchCoachingComment(updatedProperty, commentId))
    await dispatch(fetchCoachingCommentsByCall(callId))
    toast.success('Comment updated')
  } catch (err) {
    toast.error('Failed to update comment')
  }
}

export const downloadCsv = (completeColumns) => async (dispatch, getState) => {
  try {
    dispatch(setLoading({ csvDownload: true }))
    const { calls, filters } = getState().callSearch
    const csvColumns = completeColumns.map(({ format, ...column }) => {
      if (column?.accessor === 'start_time' || column?.accessor === 'end_time') {
        return { ...column, format }
      }
      return column
    })

    await dispatch(fetchSummariesForCallIds(calls.map(({ call_id }) => call_id)))

    const { calls: updatedCalls } = getState().callSearch
    const response = {
      data: updatedCalls || [],
      headers: csvColumns,
      filters: { startDate: filters.startDate, endDate: filters.endDate },
      fileName: 'Call Explorer Export',
    }

    if (!isEmpty(response.data)) {
      const csvString = parseToCsv(response)

      exportCsv(csvString, response.filters || filters, response.fileName)
    }
  } catch (err) {
    toast.error('Failed to download CSV')
  } finally {
    dispatch(setLoading({ csvDownload: false }))
  }
}

export const fetchPlaybookForCallExplorer = (playbookId) => {
  return async (dispatch) => {
    dispatch(setLoading({ playbook: true }))

    try {
      const { config } = await fetchingAPI(
        `${apiService.web}/api/configs/${playbookId}`,
        'GET',
        dispatch
      )

      dispatch(
        setCallExplorerData({
          playbook: {
            ...config,
            body: defaultsDeep(config.body, initialPlaybookBody),
          },
        })
      )
    } catch (err) {
      console.warn('Failed to fetch playbook for call explorer', err)
    } finally {
      dispatch(setLoading({ playbook: false }))
    }
  }
}
