import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Segment } from 'semantic-ui-react'
import { IconVolume3 } from '@tabler/icons-react'

import { setHardSelectedEvent } from '@/reducers/callSearch/callSearch.actions'
import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import VideoPlayer from '@/components/media/VideoPlayer'
import { useVideoPlayer } from '@/components/media/VideoPlayerContext'
import { useTranscript } from '@/components/transcript/TranscriptContext'
import { Transcript } from '@/components/transcript/Transcript'
import { Banner } from '@/components/banners/Banner'
import { POSTPROCESSED_TRANSCRIPT } from '@/components/transcript/transcript.constants'

import { AudioPlayer } from './AudioPlayer'
import { updateCallExplorerParams } from './helpers'

import './CallExplorerMediaAndTranscript.scss'

export const CallExplorerMediaAndTranscriptComponent = ({ flags }) => {
  const {
    callExplorer: {
      metadata,
      hardSelectedEvent,
      audioStatus,
      screenCaptureStatus,
      screenCaptureUrls,
      audioError,
      audioProgress: progress,
      audioPlaybackRate: playbackRate,
      audioPlaying: isPlaying,
    },
  } = useSelector((state) => state.callSearch)
  const {
    transcriptToDisplay,
    transcriptBlockRef,
    transcriptScrollerRef,
    closestTranscriptIndexRef,
    transcriptBlocksFirstTimes,
    transcriptType,
    allowAutoScroll,
  } = useTranscript()
  const showTranscriptSelector = flags?.callsExplorerTranscriptHypothesis

  const { videoRef } = useVideoPlayer()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isAudioAvailable = audioStatus === 'available'
  const isVideoAvailable = screenCaptureStatus === 'available'

  useEffect(() => {
    const onTimeUpdate = () => {
      if (transcriptType !== POSTPROCESSED_TRANSCRIPT) {
        closestTranscriptIndexRef.current = 0
        return
      }
      // if this is slow for long calls we could implement a recursive binary search. Only risk is while it may be faster
      // stack size could grow large which would be problematic for people on potato comps. We'll have to see which problem is more problematic
      // speed or stack size
      let closest = 0
      for (let i = 0; i < transcriptBlocksFirstTimes.length; i++) {
        const currentLoopFirstTime = transcriptBlocksFirstTimes[i]
        if (currentLoopFirstTime <= transcriptScrollerRef.current.currentTime) {
          closest = i
        } else if (currentLoopFirstTime > transcriptScrollerRef.current.currentTime) {
          break
        }
      }
      if (closestTranscriptIndexRef !== closest) {
        closestTranscriptIndexRef.current = closest
      }
    }
    if (transcriptScrollerRef.current !== null) {
      transcriptScrollerRef.current.addEventListener('timeupdate', onTimeUpdate)
    }
    return () => {
      if (transcriptScrollerRef.current !== null) {
        transcriptScrollerRef.current.removeEventListener('timeupdate', onTimeUpdate)
      }
    }
  }, [transcriptType])

  useEffect(() => {
    if (
      transcriptBlockRef.current !== null &&
      closestTranscriptIndexRef.current !== null &&
      allowAutoScroll
    ) {
      if (transcriptBlockRef.current.childNodes[closestTranscriptIndexRef.current]) {
        transcriptBlockRef.current.childNodes[closestTranscriptIndexRef.current].scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [closestTranscriptIndexRef.current, transcriptBlockRef.current])

  useEffect(() => {
    // if an event is selected, scroll the container to show the trigger
    // and surrounding transcript context. we check if the ref is available since it isn't if we have an audio error

    if (hardSelectedEvent && transcriptScrollerRef.current) {
      // Copy pasted from onTimeUpdate
      let closest = 0
      for (let i = 0; i < transcriptBlocksFirstTimes.length; i++) {
        const currentLoopFirstTime = transcriptBlocksFirstTimes[i]
        if (currentLoopFirstTime <= transcriptScrollerRef.current.currentTime) {
          closest = i
        } else if (currentLoopFirstTime > transcriptScrollerRef.current.currentTime) {
          break
        }
      }
      if (closestTranscriptIndexRef !== closest) {
        closestTranscriptIndexRef.current = closest
      }
    }
  }, [hardSelectedEvent])

  const handleTimestampClick = (timestamp) => {
    updateCallExplorerParams(location, history, { timestamp }, ['eventId'])
    dispatch(setHardSelectedEvent({ timestamp }))
    dispatch(setCallExplorerData({ audioPlaying: true }))
  }

  // the controls in full screen also control the audio
  const videoOnPlaying = () => {
    dispatch(setCallExplorerData({ audioPlaying: true }))
  }

  const videoOnPause = () => {
    dispatch(setCallExplorerData({ audioPlaying: false }))
  }

  const videoOnSeeked = (event) => {
    const { currentTime } = event.target
    const isFullscreen = document.fullscreenElement === videoRef.current
    const isNewEvent = currentTime !== progress

    if (isNewEvent && isFullscreen) {
      dispatch(setHardSelectedEvent({ timestamp: currentTime, ignoreVideo: true }))
    }
  }

  return (
    <div className="flex-column flex-item" style={{ width: '50%' }}>
      <Segment compact>
        <VideoPlayer
          sources={screenCaptureUrls}
          onPlaying={videoOnPlaying}
          onPause={videoOnPause}
          onSeeked={videoOnSeeked}
          progress={progress}
          playbackRate={playbackRate}
          isPlaying={isPlaying}
        />
        {audioStatus === 'expired' && (
          <Banner
            style={{ margin: isVideoAvailable ? '1rem 0' : 0 }}
            data-testid="audio-not-available"
            informative
            header="Audio for this call is not available"
            icon={<IconVolume3 />}
          >
            The audio recording is not available per your organization&apos;s data retention
            policies. The transcript and call events associated with this call will remain unchanged
            and accessible.
          </Banner>
        )}
        {audioError && audioStatus !== 'expired' && (
          <Banner
            style={{ margin: isVideoAvailable ? '1rem 0' : 0 }}
            data-testid="audio-not-available"
            informative
            header="Audio for this call is not available"
            icon={<IconVolume3 />}
          >
            Audio Player encountered an unexpected error during playback. Please refresh to continue
            listening, if issue persists please reach out to support.
          </Banner>
        )}
        {(isAudioAvailable || isVideoAvailable) && (
          <AudioPlayer
            isAudioAvailable={isAudioAvailable}
            isVideoAvailable={isVideoAvailable}
            transcriptScrollerRef={transcriptScrollerRef}
            transcriptToDisplay={transcriptToDisplay}
          />
        )}
      </Segment>
      <Transcript
        metadata={metadata}
        showTranscriptSelector={showTranscriptSelector}
        hardSelectedEvent={hardSelectedEvent}
        handleTimestampClick={handleTimestampClick}
        enableAutoScroll
      />
    </div>
  )
}

export const CallExplorerMediaAndTranscript = withLDConsumer()(
  CallExplorerMediaAndTranscriptComponent
)
