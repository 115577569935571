import { isEmpty } from 'lodash'

export const formatNestedOptions = (accessor, options, currentValues) => {
  if (isEmpty(options)) {
    return []
  }
  const { selected = [], openCategories = [], openSubcategories = [] } = currentValues
  const nestedOptions = options?.map((category) => {
    const categoryContext = `${category.cid} - ${accessor} - ${category.category}`
    const categoryExpanded = openCategories.some((openCat) => openCat === categoryContext)
    return {
      ...category,
      className: 'category',
      section: 'category',
      isParent: true,
      label: category.category,
      playbookName: category.playbookName,
      cid: category.cid,
      value: categoryContext,
      disabled: true,
      expanded: categoryExpanded,
      children: category.items.map((item) => {
        const title = typeof item === 'string' ? item : item.item
        const subCatContext = `${categoryContext} - ${title}`
        const subCatExpanded = openSubcategories?.some((openSubcat) => openSubcat === subCatContext)
        const baseProps = {
          disabled: false,
          label: title,
          cid: category.cid,
          checked: selected.some((selectedEntry) => selectedEntry === subCatContext),
          section: 'subcategory',
          className: 'subcategory',
          value: subCatContext,
          expanded: subCatExpanded,
        }
        if (item?.items?.length > 0) {
          const formattedDecklistOptions = item.items.map((decklist) => {
            // this is to hide decklist items
            const decklistContext = `${subCatContext} - ${decklist}`
            return {
              section: 'decklist',
              className: 'decklist',
              cid: category.cid,
              label: decklist,
              value: decklistContext,
              checked: selected.some((selectedEntry) => selectedEntry === decklistContext),
            }
          })

          baseProps.children = formattedDecklistOptions
        }
        return baseProps
      }),
    }
  })

  return nestedOptions
}

export const formatFlatOptions = (accessor, options, currentValues) => {
  if (isEmpty(options)) {
    return []
  }
  const { selected } = currentValues

  const flatOptions = options.map((item) => {
    const entryContext = `${accessor} - ${item}`
    return {
      disabled: false,
      label: item,
      checked: selected.some((selectedEntry) => selectedEntry === entryContext),
      section: 'subcategory',
      className: 'subcategory',
      value: entryContext,
    }
  })

  return flatOptions
}
